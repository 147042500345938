












































import { Component, Vue } from 'vue-property-decorator';
import router from '@/router';
import { DataTableHeader } from 'vuetify/types';
import { ViewAdminCMSComponent } from '@/types/cms';
import { getCMSComponents } from '@/utils/cms';

/**
 * CMSAdminComponents Component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'cms-page': () => import('@/components/cms/pages/CMSPage.vue')
    }
})
export default class CMSAdminComponents extends Vue {
    // array with cms components
    private cmsComponents: Array<ViewAdminCMSComponent> = [];

    /**
     * Function will be executed on component load
     * fetches cms components
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private created() {
        this.cmsComponents = getCMSComponents().filter(view => {
            return view.admin !== undefined;
        });
    }

    /**
     * @returns Array with all table headers
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get tableHeaders(): Array<DataTableHeader>{
        return [
            {
                text: this.$t('table.headers.name').toString(),
                value: 'view.name'
            },
            {
                text: this.$t('table.headers.actions').toString(),
                value: 'actions',
                sortable: false,
                filterable: false
            }
        ];
    }

    /**
     * Navigates to the edit page
     * 
     * @param component component
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private editComponent(component: ViewAdminCMSComponent) {
        if (!component.admin) return;

        router.push({ name: component.admin.routeName });
    }
}
